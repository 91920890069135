/* Fonts */

@font-face {
    font-family: cozy;
    src: url(./Assets/Cozybrush.otf);
}

@font-face {
    font-family: 'Modern Typewriter';
    src: url(./Assets//ModernTypewriter-YaR42.ttf);
}

@font-face {
    font-family: Barlow-ExtraLight;
    src: url(./Assets/Barlow-ExtraLight.otf);
}

@font-face {
    font-family: Barlow-Bold;
    src: url(./Assets/Barlow-Medium.otf);
}

/* Content */

body {
    overflow-x: hidden;
}
Nav {
    font-family: Barlow-ExtraLight;
}

h6 {
    font-family: Modern Typewriter;
}

p {
    font-family: Modern Typewriter; 
}

tr {
    font-family: Modern Typewriter;
}

td {
    font-family: Barlow-ExtraLight;
}

th {
    font-weight: lighter;
}

.nav-container {
    width: 90%;
}

.nav-link:hover {
    color: white;
    background-color: black;
}

.active {
    color: white !important;
    background-color: black;
}

/********* Work ***********************************/

.body-content-container {
    margin-top: 5vh;
    width: 90%;
}

.hero-title {
    font-family: Modern Typewriter;
    font-size: 4rem;
    margin-bottom: 5vh;
}

.Billie {
    color: #c60000;
}

.attitude {
    font-size: 5rem;
    font-family: cozy;
}

.hero-description {
    font-family: Modern Typewriter;
}

.cd-container {
    margin-left: 4vw;
}

.project-row {
    height:70vh;
  
}

.overlay {
    width: 100%;
    position: absolute;
    z-index: 3;
    left:0;
    opacity: 0;
}

.overlay-text {
    font-family: Cozy;
    font-size: 4rem;
    color: white;
    text-align: center;
}

.overlay-text-behance {
    font-family: Modern Typewriter;
    font-size: 2rem;
    color: white;
    text-align: center;
}

.footer-container {
    height: auto;
    padding-top: 1rem;
}

footer {
    width: 90%;
}

.copyright {
    color: white;
    font-family: Modern Typewriter;
    font-size:0.7rem;
}

.footer-links {
    color: white;
    font-family: Barlow-ExtraLight;
    text-decoration: none;
    font-size: 1.5rem;
}

.sunny-portfolio:visited {
    color: w hite;
}

/********* Silent Hill ***********************************/

.silent-hill-paragraph {
    width: 61%;
}

.margin-container {
    width: 90%;
}

.header-title {
    font-family: Cozy;
    font-size: 5rem;
}

.table-container {
    width: 50%;
}


th {
    text-decoration: underline;
    width: 50%;
}

.carousel-container {
    background-color: #f0f0f0;
    height: auto;
}

.carousel {
    width: 80%;
}

.carousel-image {
    left: -3%;
}

.iMac {
    position:absolute;
    top: 32%;
}

.sub-heading {
    font-family: Cozy;
    font-size: 4rem;
}

.brochure-container {
    background-color: #f0f0f0;
}

.brochure-image-scroll {
    height: 100%;
    
}

.brochure-row {
    height: 80vh;
}

.brochure {
    background-size: cover;
    height: 120vh;
    width: 100vw;
    background-repeat: no-repeat;
    background-attachment: fixed;
}

.more-projects {
    color: black;
    text-decoration: none;
    font-family: Barlow-ExtraLight;
    transition: 0.5s;
    z-index: 0;
}

.link-line {
    left: 46.2%;
    margin-top: .75rem;
    background-color: black;
    z-index: 1;
    position: absolute;
    height: 1px;
    width: 0px;
}

.more-projects:visited {
    color: black;
}

.more-projects:hover + .link-line {
    animation: stretch 0.5s linear ;
    animation-fill-mode: forwards;
}

@keyframes stretch {
     to {
        width: 100px;
    }
}

/********* ALF ***********************************/

.alf-table-container {
    width: 50%;
}

.alf-hero-paragraph {
    width: 60%;
}

.logo-row {
    height: 120vh;
}

.logo-text-col {
    background-color:#f0f0f0;
}

.modal-container {
    top: -2%;
}

.modal-header {
    height: 5vh;
}

.modal-body {
    height: auto;
}

.alf-thumbnail-container {
    width: 90%;
    height: auto;
}

.alf-thumbnail:hover {
    cursor:pointer;
    opacity: 0.7;
}

.zoom-container {
    height: 100%;
}

.guidelines-img {
    position:relative;
    left: -1%;
    object-fit: contain;
}

.guidelines-heading {
    position: absolute;
    font-family: Cozy;
    color: white;
    font-size: 4rem;
}

.guidelines-body-container {
    height: auto;
}

.alf-mockup-container {
    height: 42.6%;

}

.alf-mockup {
    object-fit: cover;
}

.alf-invitation {
    transform: scale(1.5);
}

.alf-site-mockup-row {
    height: 100vh;
}

.alf-site-mockup-col {
    height: 100vh;
}

.alf-site-mockup-div {
    height: 100vh;
}

.alf-site {
    background-size:cover;
    background-position: 50% 50%;
    height: 100vh;
    max-width: 100%;
    background-repeat: no-repeat;
    background-attachment: fixed;
}

.site-mockup-container {
    height: auto;
}

.site-mockup-img {
    transform: scale(1.5);
}

.alf-site-link {
    color: black;
    font-family: Cozy;
    font-size: 4rem;
    text-decoration: none;
}

.alf-site-link:visited {
    color: black;
}

.alf-site-link:hover {
    text-decoration:line-through;
}

.closing-paragraph-container {
    width: 90%;
}

.closing-paragraph {
    width: 65%;
}

/********* Cthulu ***********************************/

.table-text {
    font-family: Barlow-ExtraLight;
}

.cthulu-body-container {
    width: 90%;
    height: auto;
}

.cthulu-table-container {
    width: 40%;
}

.grave-poster {
    position:absolute;
    left: 26%;
    top: 90%;
}

.ch-poster {
    position: absolute;
    left: 70%;
    top: 70%;
}

.sketches {
    height: 90vh;
    background-color: #f0f0f0;
}

.cthulu-movie-poster-container {
    width: 90%;
}

.cthulu-poster-col {
    height: 130vh;
}

.cthulu-poster {
    left: -50%;
    top: -20%;
}

.billboard {
    background-size: cover;
    height: 62vh;
    background-position: 50% 52%;
    padding-left: 8vw;
}

.billboard-text {
    width: 23vw;
}

.book-header-container {
    background-size: cover;
    height: 80vh;
    background-attachment: fixed;
    background-position: center;
}

.book-sketch-container {
    background-color: #f0f0f0;
    height: 100vh;
}

.book-sketch-row {
    width: 90%;
}

.book-sketch-col {
    padding-left: 7vw;
}

.book-sketch-paragraph {
    width: 32vw;
    left: 45%;
    top: 30%;
}

mark {
    margin: 0 -0.4em;
    padding: 0.04em 0.4em;
    box-decoration-break: clone;
    background-color: white;
}

.typography-container {
    height: auto;
    width: 90%;
}

.book-mockup:hover {
    cursor:pointer;
    opacity: 0.7;
}

.book-paragraph {
    margin-bottom: 1rem;
}

.book {
    left: 95%;
    transition: left 1.5s ease-out;
}

.promotional-package-container {
    height: 150vh;
    background-color: #f0f0f0;
}

.promotional-package-bg-row {
    margin-left: 5vw;
    margin-right: 5vw;
    height: 65%;
}

.promotional-package-bg-col {
    background-size:150%;
    background-position:center;
    background-attachment:fixed; 
    background-repeat:no-repeat;
}

.promotional-package-text-row {
    margin-left: 5vw;
    margin-right: 5vw;
    height: 35%;
}

.short-line {
    height: 3px;
    width: 40px;
    background-color: black;
}

.cthulu-mockup-container {
    height: auto;
}

.cthulu-mockup-col {
    height: 52vh;
}

.cthulu-mockup {
    object-fit: cover;
}

.cthulu-mockup:hover {
    cursor:pointer;
    opacity: 0.7;
}

.cthulu-mockup-text-row {
    height: 20%;
}

.modal-larger {
    max-width: 78%;
}

.promotional-sub-heading {
    font-family: Cozy;
    font-size: 4rem;
}


/********* Resthaven ***********************************/

.resthaven-hero-paragraph {
    width: 69%;
}

.resthaven-body-container {
    width:90%;
    height: auto;
}

.resthaven-table-container {
    width: 50%;
}

.album-preview-row {
    height: 38%;
}

.iframe {
    height: 20.5vh;
}

.split-row-container {
    height: 100vh;
}

.split-row-col {
    background-size: cover;
    background-position: 20% 20%;
    background-repeat: no-repeat;
    background-attachment: fixed;
}

.split-row-col-full {
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
}

.parallax-container {
    height: 85vh;
    background-size: cover;
    background-position: 50% 65%;
    background-attachment: fixed;
}

.sub-heading-resthaven {
    font-family: Modern Typewriter;
    color: white;
}

.paragraph-resthaven {
    width: 60%;
    color: white;
}

.resthaven-bg-container {
    height: 100vh;
}

.resthaven-bg-row {
    background-size:cover;
    background-position: center;
    background-attachment: fixed;
    background-repeat: no-repeat;
}

/********* About ***********************************/

.origin-col {
    height: auto;
}

.about-col {
    height: 500px;
}

.billie-guitar {
    object-fit: cover;
 
}

.email {
    text-decoration: none;
    color: black;
}


/********* Arboretum ***********************************/

.arb-paragraph {
    width: 55%;
}

.arb-header-text {
    font-family: Barlow-ExtraLight;
}

.arb-container {
    background-attachment: fixed;
    background-size: cover;
}

.observations-text {
    width: 80%;
    font-family: Modern TypeWriter;
}

.arb-carousel {
    padding-left: 5%;
    padding-right: 5%;
    padding-bottom: 5%;
}

.signage-gallery-heading {
    font-family: Barlow-ExtraLight;
}

.arb-row-container-one {
    height: 700px !important;
}

.arb-row-container-two {
    height: 820px;
}

.arb-mockup-row {
    height: 60%;
}

.arb-mockup-row-two {
    height: 40%;
}

.arb-mockup-row-three {
    height: 60%;
}

.arb-mockup-row-four {
    height: 70%;
}

.arb-mockup-row-five {
    height: 30%;
}

.arb-mark {
    box-decoration-break:clone;
    background-color: rgb(116, 116, 116);
    line-height: 1.4;
    color:white;
}

.arb-mark-list {
    box-decoration-break:clone;
    background-color: black;
    padding: 0;
    line-height: 1.4;
    color:white;
    font-family: Barlow-ExtraLight;
    font-size: 1.1rem;
}

.arb-thumbnail:hover {
    cursor:pointer;
    opacity: 0.6;
}

.arb-mockup-thumbnail:hover {
    cursor:pointer;
    opacity: 0.6;
}

.carousel-mockup {
    height: 90vh;
    background-color: #ffffff;
}

/******* Marianas Mirror ********/

.MM-title {
    font-family: Cozy;
    font-size: 5rem;
    text-align: center;
}

.mm-ad:hover {
    cursor:pointer;
    opacity: 0.7;
}

.mm-description {
    width: 55%;
}

.ai-prompt {
    font-family: Barlow-ExtraLight;
}

.baume-row {
    height: 37rem;
}

#ads-row {
    height: 60rem;
}

/********* Glitch Effect ***********************************/

.overlay:hover {
    opacity: 1 !important;
}

.glitch,.glitch-img {
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: 150%;
}
        
.glitch {
    height: calc(100vh - 200px);
    margin: 0 auto;
    overflow: hidden;
    position: relative;
    width: 49%;
}
        
.glitch-img {
    background-blend-mode: none;
    background-color: transparent;
    height: calc(100% + 5px * 2);
    left: 0;
    pointer-events: none;
    position: absolute;
    top: 0;
    transform: translate3d(0, 0, 0);
    width: calc(100% + 10px * 2);
     
    .glitch:hover &:nth-child(n+2) {
       animation-duration: 2s;
       animation-delay: 0;
       animation-timing-function: linear;
       animation-iteration-count: infinite;
       animation-fill-mode: forwards;
    }
          
   .glitch:hover &:nth-child(2) {
       animation-name: glitch-anim-1;
    }
          
   .glitch:hover &:nth-child(3) {
       animation-name: glitch-anim-2;
    }
          
   .glitch:hover &:nth-child(4) {
       animation-name: glitch-anim-3;
    }
    .glitch:hover &:nth-child(5){
            animation-name: glitch-anim-4;
            background-blend-mode: overlay;
            background-color: #95009e;
    }

    .glitch:hover &:nth-child(6){
            animation-name: glitch-anim-4;
            background-blend-mode: overlay;
            background-color: #4c9e09;
    }

    .glitch:hover &:nth-child(7){
        animation-name: glitch-anim-4;
        background-blend-mode: overlay;
        background-color: #bd0029e8;
        ;
    }

    .glitch:hover &:nth-child(8){
        animation-name: glitch-anim-4;
        background-blend-mode: overlay;
        background-color: #00b4bd;
    }     
}

.c-glitch-img:nth-child(n+2) {
   opacity: 0;
}
        
@keyframes glitch-anim-1 {
   0%, 100% { 
        opacity: 1;
        transform: translate3d(40px, 0, 0) scale3d(-1, -1, 1);
        clip-path: polygon(0 2%, 100% 2%, 100% 5%, 0 5%);
    }
          
    20% {
        clip-path: polygon(0 15%, 100% 15%, 100% 15%, 0 15%);
    }
          
    30% {
        clip-path: polygon(0 10%, 100% 10%, 100% 20%, 0 20%);
    }
          
    40% {
        clip-path: polygon(0 1%, 100% 1%, 100% 2%, 0 2%);
    }
          
    50% {
        clip-path: polygon(0 33%, 100% 33%, 100% 33%, 0 33%);
    }
          
    55% {
        clip-path: polygon(0 44%, 100% 44%, 100% 44%, 0 44%);
    }
          
    60% {
        clip-path: polygon(0 50%, 100% 50%, 100% 20%, 0 20%);
    }
        
    65% {
        clip-path: polygon(0 70%, 100% 70%, 100% 70%, 0 70%);
    }
        
    70% {
        clip-path: polygon(0 80%, 100% 80%, 100% 80%, 0 80%);
    }
        
    80% {
        clip-path: polygon(0 50%, 100% 50%, 100% 55%, 0 55%);
    }
        
    85% {
        clip-path: polygon(0 60%, 100% 60%, 100% 65%, 0 65%);
    }
        
    95% {
        clip-path: polygon(0 72%, 100% 72%, 100% 78%, 0 78%);
    }
}
        
@keyframes glitch-anim-2 {
    0%, 100% { 
        opacity: 1;
        transform: translate3d(-10px, 0, 0);
        clip-path: polygon(0 25%, 100% 25%, 100% 30%, 0 30%);
    }
          
    10% {
        clip-path: polygon(0 3%, 100% 3%, 100% 3%, 0 3%);
    }
          
    15% {
        clip-path: polygon(0 5%, 100% 5%, 100% 20%, 0 20%);
    }
          
    17% {
        clip-path: polygon(0 20%, 100% 20%, 100% 20%, 0 20%);
    }
        
    19% {
        clip-path: polygon(0 40%, 100% 40%, 100% 40%, 0 40%);
    }
          
    33% {
        clip-path: polygon(0 52%, 100% 52%, 100% 59%, 0 59%);
    }
          
    35% {
        clip-path: polygon(0 60%, 100% 60%, 100% 60%, 0 60%);
    }
          
    40% {
        clip-path: polygon(0 75%, 100% 75%, 100% 75%, 0 75%);
    }
          
    45% {
        clip-path: polygon(0 65%, 100% 65%, 100% 40%, 0 40%);
    }
          
    49% {
        clip-path: polygon(0 45%, 100% 45%, 100% 50%, 0 50%);
    }
          
    50% {
        clip-path: polygon(0 14%, 100% 14%, 100% 33%, 0 33%);
    }
          
    55% {
        clip-path: polygon(0 15%, 100% 15%, 100% 35%, 0 35%);
    }
          
    60% {
        clip-path: polygon(0 15%, 100% 15%, 100% 15%, 0 15%);
    }
          
    70% {
        clip-path: polygon(0 65%, 100% 65%, 100% 60%, 0 60%);
    }
          
    80% {
        clip-path: polygon(0 80%, 100% 80%, 100% 85%, 0 85%);
    }
          
    90% {
        clip-path: polygon(0 55%, 100% 55%, 100% 65%, 0 65%);
    }
}
        
@keyframes glitch-anim-3 {
    0%, 100% {
        opacity: 1;
        transform: translate3d(0, -5px, 0) scale3d(-1, -1, 1);
        clip-path: polygon(0 1%, 100% 1%, 100% 3%, 0 3%);
    }
          
    5% {
        clip-path: polygon(0 10%, 100% 10%, 100% 9%, 0 9%);
    }
          
    11% {
        clip-path: polygon(0 5%, 100% 5%, 100% 6%, 0 6%);
    }
          
    20% {
        clip-path: polygon(0 20%, 100% 20%, 100% 20%, 0 20%);
    }
          
    25% {
        clip-path: polygon(0 10%, 100% 10%, 100% 10%, 0 10%);
    }
          
    35% {
        clip-path: polygon(0 30%, 100% 30%, 100% 25%, 0 25%);
    }
          
    42% {
        clip-path: polygon(0 15%, 100% 15%, 100% 16%, 0 16%);
    }
          
    48% {
        clip-path: polygon(0 40%, 100% 40%, 100% 39%, 0 39%);
    }
          
    50% {
        clip-path: polygon(0 20%, 100% 20%, 100% 21%, 0 21%);
    }
          
    56% {
        clip-path: polygon(0 60%, 100% 60%, 100% 55%, 0 55%);
    }
          
    61% {
        clip-path: polygon(0 30%, 100% 30%, 100% 31%, 0 31%);
    }
          
    68% {
        clip-path: polygon(0 70%, 100% 70%, 100% 69%, 0 69%);
    }
          
    72% {
        clip-path: polygon(0 40%, 100% 40%, 100% 41%, 0 41%);
    }
          
    77% {
        clip-path: polygon(0 80%, 100% 80%, 100% 75%, 0 75%);
    }
          
    81% {
        clip-path: polygon(0 50%, 100% 50%, 100% 51%, 0 51%);
    }
          
    86% {
        clip-path: polygon(0 90%, 100% 90%, 100% 90%, 0 90%);
    }
          
    90% {
        clip-path: polygon(0 60%, 100% 60%, 100% 60%, 0 60%);
    }
          
    92% {
        clip-path: polygon(0 100%, 100% 100%, 100% 99%, 0 99%);
    }
          
    94% {
        clip-path: polygon(0 70%, 100% 70%, 100% 71%, 0 71%);
    }
}
        
@keyframes glitch-anim-4 {    
    0%, 100% {
        opacity: 0.5;  
    }
}

/********* Media Queries ***********************************/

 @media only screen and (max-width: 1700px) {
    .ch-poster {
        top: 72%;
    }

    #ads-row {
        height: 53rem;
    }
 }

 @media only screen and (max-width: 1500px) {

    .glitch {
        width: 48.8%;
    }

    .alf-table-container {
        width: 60%;
    }

    .cthulu-table-container {
        width: 55%;
        text-align: left;
    }

    .grave-poster {
        left: 33%; 
    }

    .billie-guitar {
        object-position: 50% 300%;
    }

    #ads-row {
        height: 44rem;
    }
 }

 @media only screen and (max-width: 1400px) {

    .book-paragraph {
        margin-bottom: 0px;
    }

    .link-line {
        left: 45.6%;
    }
 }

 @media only screen and (max-width: 1350px) {
    .glitch, .glitch-img {
        background-size: cover;
    }

    #ads-row {
        height: 40rem;
    }
 }

 @media only screen and (max-width: 1200px) {

    .cthulu-table-heading {
        width: 50%;
    }

    .silent-hill-web {
        width: 80%;
    }

    .table-container {
        width: 60%;
    }

    .alf-table-container {
        width: 75%;
    }

    .cthulu-table-container {
        width: 65%;
    }

    .iMac {
        top: 32%;
        left: 5%;
    }

    .brochure {
        background-position: 40% 50%;
    }

    .mood-board {
        width: 100%;
    }

    .alf-mockup-container {
        height: 45.8%;
    }

    .alf-mockup {
        width: 96.2%;
    }

    .ch-poster {
       top: 74%;
    }

    .cthulu-body-container {
        height: auto;
    }

    .book {
        display: none;
    }

    .typography-container {
        height: auto;
        margin-bottom: 3rem;
    }

    .book-mockup {
        height: 32.2%;
    }

    .billboard-text {
        width: 28vw;
    }

    .book-sketch-paragraph {
        top: 33%;
    }

    .cthulu-mockup-row {
        height: 33%;
    }

    .bw-resthaven-col {
        margin-top: 5.5rem;
    }

    .link-line {
        left: 44.8%;
    }

    .billie-guitar {
        object-position: 20% -120px;
    }

    .arb-mockup-row {
        height:66% !important;
    }

    .arb-mockup-row-two {
        height:34% !important;
    }

    #ads-row {
        height: 38rem;
    }
}

@media only screen and (max-width: 1020px) {
    .grave-poster {
        top: 89%;
    }

    .ch-poster {
        top: 69%;
    }

    #ads-row {
        height: 30rem;
    }
}

 @media only screen and (max-width: 992px) {
    .cd {
        width:90%;
    }

    .hero-title {
        font-size: 4.5rem;
    }

    .silent-hill-web {
        width: 90%;
    }

    .table-container {
        width: 70%;
    }

    .alf-table-container {
        width: 85%;
    }

    .cthulu-table-container {
        width: 75%;
    }

    .iMac {
        top:32%;
        left: 5%;
    }

    .brochure {
        height: 90vh;
        background-position: 38% 30%;
    }

    .link-line {
        left: 43.2%;
    }

    .alf-thumbnail-container {
        height: 50vh;
    }

    .alf-thumbnail {
        height: 94%;
    }

    .modal-body {
        height: auto;
    }

    .modal-container {
        top: 6%;
    }

    .modal-90w {
        max-width: 68%;
    }

    .modal-img {
        object-fit: contain;
    }

    .zoom-container {
        height: 120vh;
    }

    .guidelines-body-container {
        max-width: 90%;
    }

    .alf-invitation-container {
        height: 28%;
    }

    .alf-invitation {
        height: 100%;
        object-fit: cover !important;
        
    }

    .alf-mockup {
        width: 100%;
   
    }

    .alf-site {
        height: 100vh;
        background-position: 50% 2%;
    }   

    .mood-board {
        width: 100%;
    }

    .grave-poster, .ch-poster {
        display: none;
    }

    .cthulu-body-container {
        height: auto;
    }

    .billboard-text {
        width: 35vw;
    }

    .book-sketch-paragraph {
        top: 31%;
    }

    .book-sketch {
        width: 70%;
    }

    .book-mockup {
        height: 30.4%;
    }

    .promotional-package-container {
        height: 200vh;
    }

    .promotional-package-text {
        width: 100% !important;
    }

    .promotional-package-text-row {
        padding-top: 10vh;
        padding-bottom: 10vh;
    }

    .bw-resthaven-col {
        margin-top: 0rem;
    }

    .split-row-col {
        background-position: 69% 30px;
    }

    .paragraph-resthaven {
        width: 80vw;
    }

    .bandcamp-div {
        width: 100% !important;
    }

    .lips {
        height: 20%;
        width: 20%;
    }

    .about-col {
        height: 500px;
    }

    .billie-guitar {
        object-fit: contain;
        object-position: 50% -20%;
    }

    .arb-row-container-one {
        height:auto !important;
    }

    .mm-description {
        width: 70%;
    }

    #ads-row {
        height: 25rem;
    }

}

@media only screen and (max-width: 768px) {

    .cd {
        width: 100%;
    }

    .cd-container {
        margin-left: 0;
    }

    .silent-hill-web {
        width: 100%;
    }

    .carousel {
        width: 100%;
    }

    .iMac {
        top:25%;
        left: 5%;
    }

    .brochure-container {
        padding-top: 10vh;
        height: auto;

    }

    .brochure-sub {
        margin-bottom: 2vh !important;
    }

    .brochure {
        height: 90vh;
        background-attachment: initial;
        background-position: 38% 30%;
    }

    .brochure-row {
        height: auto;
    }

    .link-line {
        left: 40.7% !important;
        display: none;
    }

    .alf-table-container {
        width: 95%;
    }

    .cthulu-table-container {
        width: 85%;
    }

    .alf-thumbnail-container {
        height: 39vh;
    }

    .zoom-container {
        height: 100vh;
    }
    
    .alf-mockup-container {
        height: 25.3%;
    }

    .alf-site {
        height: 80vh;
        background-position: 60% -2%;
        background-attachment: initial;
    }  

    .site-mockup-container {
        height: auto;
    }

    .alf-logo-container {
        height: 230vh;
    }

    .alf-logo {
        display: none;
    }

    .alf-logo-container-col {
        display: none !important;
    }

    .alf-logo-whole-container {
        display: flex !important;
    }

    .break {
        display: block !important;
    } 

    .logo-text-col {
        height: 50% !important;
    }

    .logo-row {
        height: 180vh;
    }

    .site-mockup-container {
        height: auto;
    }

    .table-container {
        width: 80%;
    }

    .billboard-text {
        width: 38vw;
    }

    .book-header-container {
        background-attachment: initial;
    }

    .book-sketch-paragraph {
        top: 32%;
        left: 50%;
        width: 45%;
    }

    .book-sketch {
        width: 90%;
    }

    .book-mockup {
        height: 32%;
        pointer-events: none;
    }

    .promotional-package-bg-row {
        height: 40%;
        background-attachment: initial;
    }

    .promotional-package-container {
        height: auto;
    }
    .promotional-package-bg-col {
        height: 100vh;
        background-attachment: initial;
    }

    .promotional-package-text-row {
        flex-direction: column;
    }

    .short-line {
        display: none;
    }

    .resthaven-table-container {
        width: 65%;
    }

    .resthaven-bg-row {
        background-position: 38% 50% ;
        background-attachment: initial;
    }

    .split-row-col {
        background-attachment: initial;
    }

    .split-row-col-full {
        display: none;
    }

    .split-row-container {
        margin-bottom: 1.5rem;
    }

    .paragraph-resthaven {
        width: 90vw;
    }

    .resthaven-link-line {
        display: none;
    }

    .parallax-container {
        background-attachment: initial;
    }

    .origin-col {
        height: 1000px;
    }

    .about-col {
        height: auto;
    }

    .billie-guitar {
        object-fit: cover;
        object-position: 50% -150px;
    }

    .lips {
        height: 15%;
        width: 15%;
    }
    
    .baume-row {
        height: auto;
    }

    #ads-row {
        height: auto;
    }
}

@media only screen and (max-width: 680px) {
    .project-row {
        height: 105vh;
    }
    
    .glitch {
        height: 50vh !important;
        width: 100%;
        background-position: 70% 50%;
    }

    .glitch-img {
        background-position: 70% 50%;
    }
    .overlay-text {
        font-size: 3rem;
    }

    .mm-paragraph {
        width: 60% !important;
    }
}

@media only screen and (max-width: 576px) {
    .navlink-container {
        display: block !important;
    }

    .silent-hill-web {
        width: 100%;
    }

    .iMac {
        position:initial;
        width:100%;
    }

    .silent-hill-web-two {
        display: none;
    }

    .web-design-sub {
        text-align: center;
    }
    
    .brochure-container {
        padding-top: 10vh;
    }

    .brochure-sub {
        margin-bottom: 2vh !important;
    }

    .brochure {
        height: 70vh;
        background-position: 38% 30%;
    }

    .link-line {
        left: 39.7%;
    }

    .alf-table-container {
        width: 100%;
    }

    .cthulu-table-container {
        width: 95%;
    }

    .logo-row {
        height: 180vh;
    }

    .modal-container {
        top: 10%;
        left: 14%;
    }
    
    .mood-board {
        height: auto;
    }

    .mood-board-text {
        margin-top: 15vh;
    }

    .alf-logo-container {
       height: auto;
    }

    .logo-text-container {
        width: 90% !important;
    }

    .alf-thumbnail-container {
        height: auto;
    }

    .zoom-container {
        height: auto;
    }

    .site-mockup-container {
        height: auto;
    }

    .site-mockup-text {
        width: 90%;
    }

    .site-mockup-img {
        width: 90%;
    }

    .table-container {
        width: 90%;
    }

    .billboard-text {
        position: relative;
        width: 41vw;
        top: -25%;
    }
    .book-sketch-container {
        height: auto;
    }

    .book-sketch {
        width: 100%;
    }

    .book-sketch-col {
        padding: 0;
        flex-direction: column;
    }

    .book-sketch-paragraph {
        position:relative !important;
        top:auto;
        left: auto;
        width: 100%;
     }

     .promotional-package-bg-col {
        height: 70vh;
     }

     .promotional-sub-heading {
        text-align: center;
     }

     .cthulu-mockup {
        pointer-events: none;
     }

     .link-line {
        left: 41%;
     }

     .resthaven-bg-row {
        background-position: 44% 50%;
     }

     .resthaven-table-container {
        width: 85%;
    }

    .resthaven-history-container {
        width: 90%;
    }

    .resthaven-link-line {
        left: 41%;   
    }

    .billie-guitar {
        object-position: -70px -150px;
    }

    .MM-title {
        font-size: 4em;
    }

    .mm-description {
        width: 90%;
    }

}

@media only screen and (Max-width: 501px) {

    .attitude-lips {
        display: none;
    }
    .link-line { 
        left: 39.5% !important;
    }

    .sketches {
        height: 50vh;
    }

    .carousel-mockup {
        height: 50vh;
    }

    .resthaven-bg-container {
        height: 50vh;
    }

    .resthaven-table-container {
        width: 100%;
    }

    .table-container {
        width: 100%;
    }

    .cthulu-table-container {
        width: 100%;
    }

    .mm-paragraph {
        width: 70% !important;
    }
}

@media only screen and (max-width: 482px) {
    .attitude-lips {
        display: inline;
    }
}

@media only screen and (max-width: 411px) {
    .footer-link-container {
        gap: 1.5rem !important;
    }

    .billboard-text {
        width: 55vw;
    }

    .resthaven-bg-row {
        background-position: 46% 50%;
    }
}
